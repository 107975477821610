import React from 'react';
import * as styles from './News.module.scss';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from "@images/home/favicon.ico";

import NewsPageBase from '@components/NewsPage';
import Layout from "@home/Layout";
import Seo from "@components/seo";

const News = () => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicNewsPost(filter: {data: {page_selection: { in:["Gomes", "Homepage"]}}}) {
        nodes {
          tags
          id
          data {
            featured
            external_link {
              url
              target
            }
            title {
              text
            }
            intro_text {
              text
            }
            author {
              text
              html
            }
            author_image {
              url
            }
            main_image {
              url
            }
            small_image {
              url
            }
            page_selection
          }
        }
      }
    }
  `);

  const newsPosts = data.allPrismicNewsPost.nodes;

  return (
    <Layout>
      <Seo title={"News | By Gomes"} icon={favicon} />
      <NewsPageBase styles={styles} newsPosts={newsPosts} CustomNewsMore={() => <button>LOAD MORE NEWS</button>} />
    </Layout>
  );
};

export default News;
