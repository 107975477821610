// extracted by mini-css-extract-plugin
export var relativeWrapper = "News-module--relativeWrapper--1gkEN";
export var decor = "News-module--decor--3kqC1";
export var container = "News-module--container--2xXVQ";
export var newsFeatureContainer = "News-module--newsFeatureContainer--1HCYa";
export var newsFeatureContent = "News-module--newsFeatureContent--i7g38";
export var newsAuthorBlock = "News-module--newsAuthorBlock--2KpuP";
export var newsFeatureMedia = "News-module--newsFeatureMedia--V3nsm";
export var newsPostContainer = "News-module--newsPostContainer--1W9Zk";
export var newsPostList = "News-module--newsPostList--2xDmk";
export var newsMore = "News-module--newsMore--_paTv";
export var button = "News-module--button--C3R4y";
export var lineAbove = "News-module--lineAbove--IFdyg";
export var newsPost = "News-module--newsPost--Hyyfl";